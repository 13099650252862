$ppi-blue: #0b78be;
$ppi-green: #45b598;
$ppi-red: #e64e4e;
$ppi-orange: #e9531f;

$cysmo-green: #97bf0d;

$border-gray: #acbbca;

$border-light-blue: #d8e0ea;

$border-dark-blue: #0072bb;

/*deprecated*/
$rating-blue: #51b3d5;

$background-white: #edf6ff;

$selection-blue: #2184c3;

$positive: #b3e30f;

$negative: #f55517;

$card-header-blue: #032e4c;
$text-color: #333333;

$blue-text: $ppi-blue;
$gray-text: #6c7d8f;

$intermediate-1: #3475b8;
$intermediate-2: #62b2bc;
$intermediate-3: #62b2bc;
$intermediate-4: #3475b8;
$intermediate-5: #38cbd7;

$list-item-background: #ffffff;

$btn-green: #63d893;
$btn-red: #e64e4e;

.card-header-blue {
  color: $card-header-blue;
}

.color-ppi-blue {
  color: $ppi-blue;
}

.color-gray {
  color: gray;
}

.color-gray-text {
  color: $gray-text;
}

.color-unset {
  color: unset;
}

.color-green {
  color: $ppi-green;
}

.color-red {
  color: $ppi-red;
}

.color-positive {
  color: $positive;
}

.color-negative {
  color: $negative;
}

@use '@angular/material' as mat;

/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/scss/bootstrap';
@import 'ppi-theme';
@import 'ppi-colors';

html,
body {
  position: relative;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.text-monospace {
  font-family: monospace;
}

.full-width {
  max-width: 100%;
}

.default-width {
  max-width: 1600px;
}

.main-content {
  height: 94%;
}

.table-light-colors {
  tbody > tr:hover {
    background-color: var(--light-background-hover) !important;
  }
}

.table-dark-colors {
  tbody > tr:hover {
    background-color: var(--dark-background-hover) !important;
  }
}

.month-picker .mat-calendar-period-button {
  pointer-events: none;
  background-color: var(--dark-background-hover) !important;
}

.month-picker .mat-calendar-arrow {
  display: none;
  background-color: var(--dark-background-hover) !important;
}

.timesheet-calendar .mat-calendar-period-button {
  pointer-events: none;
  background-color: var(--dark-background-hover) !important;
}

.timesheet-calendar .mat-calendar-arrow,
.timesheet-calendar .mat-calendar-previous-button,
.timesheet-calendar .mat-calendar-next-button {
  display: none;
  background-color: var(--dark-background-hover) !important;
}

.fit-content {
  width: fit-content;
}

.snack-primary {
  background-color: var(--primary-color) !important;
  color: var(--text-primary-darker-color) !important;

  button {
    color: var(--text-primary-darker-color);
  }
}

.snack-accent {
  background-color: var(--accent-darker-color) !important;
  color: var(--text-accent-lighter-color) !important;

  button {
    color: var(--text-accent-lighter-color);
  }
}

.snack-warn {
  background-color: var(--warn-color) !important;
  color: var(--text-warn-darker-color) !important;

  button {
    color: var(--text-warn-darker-color);
  }
}

.snack-yellow {
  background-color: var(--warn-color-secondary) !important;
  color: var(--text-warn-darker-color) !important;

  button {
    color: var(--text-warn-darker-color);
  }
}

.dark-button {
  background-color: #393939;
  color: var(--text-accent-lighter-color);
}

.cdk-overlay-pane {
  width: auto !important;
  min-width: 120px;
  max-width: 750px;
}

.accent-lighter {
  background-color: var(--accent-lighter-color) !important;
}

.mat-calendar-body-cell.selected-date-calendar {
  background-color: var(--primary-color);
  border-radius: 50%;
}

.mat-calendar-body-cell.selected-date-calendar .mat-calendar-body-cell-content {
  color: var(--text-primary-color);
}

mat-form-field input {
  height: 1.125em;
}

.buttons-right {
  display: flex;
  justify-content: flex-end;
}

.dialog-title {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin: 0 !important;
}

/* Border above the input field
   Note: we added the class="calculation-input" to all .mat-form-field-appearance-legacy
   elements in the calculation table, so that the style doesn't overwrite all inputs. */
.calculation-input .mat-form-field-infix {
  padding: 0em 0 !important;
  border: 0 !important;
}

/* Wrapper around the input field */
.calculation-input .mat-form-field-wrapper {
  padding-bottom: 0em !important;
}

/* Move input field underline further down because we removed the border. */
.calculation-input .mat-form-field-underline {
  //display: none !important;
  transform: translateY(1.5em);
}

/* Make the icon smaller so that its the size of the text */
.costume-icon {
  transform: scale(0.5) !important;
  height: 1em !important;
  line-height: 1 !important;
}

/* Space the icons properly apart */
.costume-icon {
  height: 1em !important;
  width: 2em !important;
}

/*.calculation-row.row, */
.calculation-row {
  padding-left: 0.375rem !important;
  padding-right: 0.375rem !important;
}

.outer-calculation-row {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.cell-right {
  text-align: right !important;
}
